import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MetaService } from 'app/services/meta.service';
import { NavVerticalService } from './../../services/nav-vetical.service';
import { BaseComponent } from '../base/base.component';
import { FragmentService } from 'app/services/fragment.service';
import { LoaderService } from 'app/services/loader.service';
import { DocPageNotificationService } from 'app/services/docpage-notification.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.scss'],
  animations: [
    trigger('showHide', [
      state(
        'show',
        style({
          opacity: 1,
        })
      ),
      state(
        'hide',
        style({
          height: '0px',
          opacity: 0,
        })
      ),
      transition('show => hide', [animate('0.3s 100ms ease-out')]),
      transition('hide => show', [animate('0.3s 100ms ease-out')]),
    ]),
  ],
})
export class DocComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('nav') nav: ElementRef;
  @ViewChild('content') content: ElementRef;
  slideNav = false;
  docIndexReady = false;
  isDocIndex = false;
  notification = null;
  isNotificationReady = false;
  contentViewObserver: MutationObserver;

  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService,
    private navVerticalService: NavVerticalService,
    private notificationService: DocPageNotificationService
  ) {
    super(fragmentService, loaderService, metaService);
  }

  ngOnInit() {
    this.metaService.metadata.pipe(distinctUntilChanged()).subscribe((pMeta) => {
      this.docIndexReady = false;
      this.isDocIndex = pMeta && pMeta['page-index'] === undefined ? true : pMeta['page-index'];
    });
    this.notificationService.notification.subscribe((notification: any) => {
      this.notification = notification;
      this.isNotificationReady = notification.show;
    });
  }

  ngAfterViewInit(): void {
    const contentViewerElement = document.querySelector('content-viewer');
    this.contentViewObserver = new MutationObserver(() => {
      this.docIndexReady = true;
    });
    this.contentViewObserver.observe(contentViewerElement, { attributes: true });
  }

  unsetNotification(isDismissed: boolean): void {
    this.isNotificationReady = !isDismissed;
  }

  // For mobile
  showNav() {
    this.slideNav = !this.slideNav;
    this.navVerticalService.showNav(this.nav, this.content, this.slideNav);
  }

  ngOnDestroy(): void {
    if(this.contentViewObserver) {
      this.contentViewObserver.disconnect();
    }
  }
}
