import { Component, Input } from '@angular/core';
import { dropdownLink } from 'app/models/dropdownLink.interface';

@Component({
  selector: 'header-menu-dropdown',
  templateUrl: './header-menu-dropdown.component.html',
})
export class HeaderMenuDropdownComponent {
  @Input() headerText: string;
  @Input() dropdownTo: dropdownLink[];

  constructor() {}

}
