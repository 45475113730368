import { LocalStorageService } from '../../services/localStorage.service';
import { Component, OnInit, Input } from '@angular/core';
// import { isScullyRunning } from '@scullyio/ng-lib';
import { ConfigService } from 'app/services/config.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cookie-notification',
  templateUrl: './cookie-notification.component.html',
  styleUrls: ['./cookie-notification.component.scss'],
})
export class CookieNotificationComponent implements OnInit {
  @Input() message: string;
  showNotification = true;
  // scully: boolean = isScullyRunning();
  scully = false;
  subscription = new Subscription();

  constructor(private localStorageService: LocalStorageService, private configService: ConfigService) {}

  ngOnInit() {
    if (this.localStorageService.getItem('cookieNotification')) {
      if (this.localStorageService.getItem('cookieNotification') === 'false') {
        this.showNotification = false;
      }
    }

    if (this.showNotification) {
      this.subscription.add(
        this.configService.getConfig('site').subscribe((config) => {
          config.Items.forEach((item: { id: string; message: string }) => {
            if (item.id === 'site') {
              this.message = item.message ? item.message : null;
            }
          });
        })
      );
    }
  }

  handleCloseClick() {
    this.showNotification = false;
    this.localStorageService.setItem('cookieNotification', false);
  }
}
