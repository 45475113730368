import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class Globals {
  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  isBrowser: boolean;
  TEMPLATE_TYPES = {
    SIDEBAR: 'sidebar',
    ARTICLE: 'article',
    TECHNICAL: 'technical',
    LISTING: 'listing',
    SERVICE_DISCOVERY: 'service_discovery',
    LANDING: 'landing',
    REDOC: 'redoc',
    PRODUCT: 'product',
    APIS_PAGE: 'solution-finder',
    HOMEPAGE: 'homepage',
    API_OVERVIEW: 'api-overview',
    DOC_PAGE: 'doc-page',
    PUBLIC: 'public'
  };
  DATE_FORMAT = 'D MMM YYYY';
  static emailValidationRegEx = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,4}$';
}
