<app-back-to-top></app-back-to-top>
<div *ngIf="mode=='primary'" class="footer -primary">
  <div class="-card">
    <h1 class="-fontDisplayClanOffcProUltra">
      STILL CAN’T FIND
      <span class="-fontColorGreen"> WHAT YOU’RE LOOKING FOR?</span>
    </h1>
  </div>
  <div class="-card -contact">
    <h4>Let’s start a</h4>
    <h3 class="-fontDisplayClanOffcProUltra">CONVERSATION</h3>
    <p>
      Contact us to learn more or discuss how our solutions can help your business grow.
    </p>
    <div class="-channelContainer d-md-flex flex-wrap">
      <div class="-channel">
        <h4 class="-fontDisplayClanOffcProUltra">Contact Sales</h4>
        <div class="-link-icon-wrapper -fontDisplayThin">
          <app-link [to]="'/contact-sales'">Contact US Sales</app-link>
        </div>
        <div class="-link-icon-wrapper -fontDisplayThin">
          <app-link [to]="'https://www.fisglobal.com/contact-us'">Contact Global Sales</app-link>
        </div>
      </div>
    </div>
  </div>
  <div class="-card -support">
    <h4>Need</h4>
    <h3 class="-fontDisplayClanOffcProUltra">SUPPORT</h3>
    <p>
      Ask our developer community.
    </p>
    <div class="-button-group">
      <div class="-link-icon-wrapper -started-btn">
        <wpbutton to="/docs" class="-secondary" to="https://community.fisglobal.com">Get Support
        </wpbutton>
      </div>
      <div class="footer -stay-connected">
        <h4 class="-fontDisplayClanOffcProUltra">Stay Connected</h4>
        <div *ngIf="socialMenu" class="-social">
          <a *ngFor="let item of socialMenu" href="{{ item.path }}" target="_blank"
            rel="noopener noreferrer" aria-label="SocialMedia">
            <button-icon icon="{{item.icon}}" iconcolor="-colorAccentLightBlue"
              iconhovercolor="-colorPrimaryGreen">
            </button-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="mode=='secondary'" class="footer -secondary">
  <div class="-secondary-container">
    <div class="-card -contact-bottom-card1">
      <div class="-channel">
        <h4 class="-fontDisplayClanOffcProUltra">Contact Sales</h4>
        <div class="-link-icon-wrapper -fontDisplayThin">
          <app-link [to]="'/contact-sales'">Contact US Sales</app-link>
        </div>
        <div class="-link-icon-wrapper -fontDisplayThin">
          <app-link [to]="'https://www.fisglobal.com/contact-us'">Contact Global Sales</app-link>
        </div>
      </div>
    </div>
    <div class="-card -contact-bottom">
      <div>
        <p>
          Ask our developer community.
        </p>
        <div class="-button-group">
          <div class="-link-icon-wrapper -started-btn">
            <wpbutton to="/docs" class="-secondary" to="https://community.fisglobal.com">Get Support
            </wpbutton>
          </div>
        </div>
      </div>
    </div>
    <div class="-card -support">
      <div>
        <h4 class="-fontDisplayClanOffcProUltra">Stay Connected</h4>
        <div *ngIf="socialMenu" class="-social">
          <a *ngFor="let item of socialMenu" href="{{ item.path }}" target="_blank"
            rel="noopener noreferrer" aria-label="SocialMedia">
            <button-icon icon="{{item.icon}}" iconcolor="-colorAccentLightBlue"
              iconhovercolor="-colorPrimaryGreen">
            </button-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer -container">
  <div class="-copyright">
    <p>©{{ date }} FIS, LLC and/or its affiliates. All rights reserved.</p>
    <ul>
      <li>
        <app-link [to]="'https://www.fisglobal.com'">fisglobal.com</app-link>
      </li>
      <li>
        <app-link [to]="'/terms-of-service'">Terms of Service</app-link>
      </li>
      <li>
        <app-link [to]="'https://www.fisglobal.com/en-gb/privacy'">Privacy notice</app-link>
      </li>
      <li>
        <app-link [to]="'https://www.fisglobal.com/en-gb/cookies'">Cookies</app-link>
      </li>
    </ul>
  </div>
</div>