<div class="-left-pane">
  <h3>
    Solutions
  </h3>
  <p>
    FIS offers a broad range of API products to suit every kind of business, no matter the size or location. Answer a few questions and let us help find what you are looking for.
  </p>
  <span class="-left-pane-link">
    <app-link [to]="'/solution-finder'" >Find your solution</app-link>
  </span> 
</div>
<div class="-right-pane">
  <header-tabs></header-tabs>
</div>