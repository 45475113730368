<div class="search -container" [@searchOpenState]="openState">
  <button class="search -close -fontSmall"
    (click)="handleCloseClick()">
    <span *ngIf="!isTouch">Type <span class="wpdh-type-key">esc</span> to close</span>
    <span *ngIf="isTouch">Press to close</span>
    <button-icon
      class="search -close-icon"
      icon="close"
      iconcolor="-colorAccentRed">
    </button-icon>
  </button>

  <div class="search -head">
    <div class="search -head-container">
      <div class="row">
        <div class="col-md-8 col-md-offset-2">
          <label>
            <h1 class="search -search-title">Search</h1>
            <div class="search -input-wrapper d-flex flex-row">
              <button-icon
                class="p-2 search -search-icon"
                icon="search"
                iconattributes="-no-cursor"
                iconcolor="-colorSupportingWhite">
              </button-icon>
              <input
                class="p-2"
                #searchInput type="text"
                placeholder="Enter Product, API, or keywords"
                (keyup)="search(searchInput.value)"/>
              <button-icon
                (click)="removeInputText()"
                class="p-2 search -input-close-icon"
                icon="close" 
                iconhovercolor="-colorPrimaryGreen"
                iconcolor="-colorSupportingWhite">
              </button-icon>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="search -results">
    <div class="search -results-inner">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2">
            <app-search-view
              *ngIf="hits"
              [hits]="hits"
              [start]="start"
              [updating]="updating"
              [noResults]="noResults"
              [activePage]="activePage"
              (onFilter)="selectFilter($event)"
              (onPager)="selectPage($event)">
            </app-search-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
