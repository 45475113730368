<div class="link-nav" *ngIf="menu">
  <div [ngClass]="menuItem.children ? 'header-menu-dropdown' : ''"  class="-link-items" *ngFor="let menuItem of menu">
    <a
      *ngIf="!menuItem.children && !menuItem.external"
      [ngClass]="router.url.includes(menuItem.path) ? '-active' : ''"
      [routerLink]="'/' + menuItem.path"
      aria-label="MainNav">
        {{ menuItem.title }}
    </a>
    <a
      *ngIf="!menuItem.children && menuItem.external"
      [ngClass]="router.url.includes(menuItem.path) ? '-active' : ''"
      href="{{ menuItem.path }}"
      target="_blank" 
      rel="noopener noreferrer" 
      aria-label="MainNav">
        {{ menuItem.title }}
    </a>
    <app-link *ngIf="menuItem.children">{{ menuItem.title }}<icon icon="menu-green-icon" class="-custom-size-icon"></icon></app-link>
    <header-menu-dropdown *ngIf="menuItem.children" class="-menu"></header-menu-dropdown>
  </div>
</div>
