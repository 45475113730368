import { Component, Input, OnInit } from '@angular/core';
import { dropdownLink } from 'app/models/dropdownLink.interface';
import { CognitoService } from 'app/services/cognito.service';
@Component({
  selector: 'account-dropdown',
  templateUrl: './account-dropdown.component.html',
})
export class AccountDropdownComponent implements OnInit {
  @Input() headerText: string;
  @Input() apiSpecList: dropdownLink[];
  dropdownTo: dropdownLink[] = [
    {
      path: 'dashboard',
      title: 'Dashboard',
    },
    {
      path: 'dashboard/account',
      title: 'Profile',
    },
    {
      path: 'account/logout',
      title: 'Sign out',
    },
  ];

  constructor(private cognitoService: CognitoService) {}

  ngOnInit(): void {
    if(this.apiSpecList && this.apiSpecList.length) {
      this.dropdownTo = this.apiSpecList;
    }
  }

  signOut() {
    this.cognitoService.logout();
  }
}
