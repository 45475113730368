<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="form">
    <div *ngFor="let question of questions">
      <wp-form-question [question]="question" [form]="form" *ngIf="!['radio', 'checkbox'].includes(question.type); else groupTemplate;"></wp-form-question>
      <ng-template #groupTemplate>
        <wp-form-group [question]="question" [form]="form"></wp-form-group>
      </ng-template>
    </div>
    <div [class]="'d-flex form ' + submitClasses">
      <wpbutton type="submit" (click)="onSubmit()" disableRoute="true">
        {{ submitText }}
      </wpbutton>
    </div>
  </form>
</div>

<!-- <div class="wpdh-feedback-form__form-wrapper margin-reset-children text-center"
  [@successState]="successState">
  <button class="wpdh-feedback-form__close-button" (click)="closeSuccess()">Close</button>
  <div class="wpdh-feedback-form__status-icon wpdh-feedback-form__status-icon--success"></div>
  <h3>Thank you for your submission!</h3>
</div> -->
<!-- <div class="wpdh-feedback-form__form-wrapper margin-reset-children text-center"
  [@failureState]="failureState">
  <button class="wpdh-feedback-form__close-button" (click)="closeError()">Close</button>
  <div class="wpdh-feedback-form__status-icon wpdh-feedback-form__status-icon--failure"></div>
  <h3>Error! Something went wrong</h3>
</div> -->
