import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { KeyboardService } from '../../services/keyboard.service';
import { SearchService } from '../../services/search.service';
import { TouchService } from '../../services/touch.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-search-window',
  templateUrl: './search-window.component.html',
  styleUrls: ['./search-window.component.scss'],
  animations: [
    trigger('searchOpenState', [
      state(
        'true',
        style({
          'z-index': 5,
          opacity: 1,
          transform: 'none',
        })
      ),
      state(
        'false',
        style({
          'z-index': -1,
          opacity: 0,
          transform: 'translateY(100px)',
        })
      ),
      transition('true <=> false', animate('200ms ease-in')),
    ]),
  ],
})
export class SearchWindowComponent implements OnInit, AfterViewInit {
  toggles$: Observable<any[]>;
  openState = false;
  searchTerms = new Subject<any>();
  searchResponse: Observable<any>;
  activeFilter: any = {};
  activePage: any = null;
  start = true;
  updating = false;
  noResults = false;
  hits: any = {};
  term = '';
  isTouch = false;

  @ViewChild('searchInput', { static: true }) input: ElementRef;

  constructor(
    protected keyboardService: KeyboardService,
    protected searchService: SearchService,
    public route: ActivatedRoute,
    public touchService: TouchService
  ) {}

  ngOnInit() {
    this.isTouch = this.touchService.checkTouch();
    
    this.searchResponse = this.searchTerms.pipe(
      debounceTime(500),

      distinctUntilChanged(),

      switchMap((query: string) => this.searchService.getSearch(query))
    );

    this.searchResponse.subscribe(
      (results) => {
        gtag('event', 'page_view', {
          page_path: '/Search?q='+this.term
       });
        this.updating = false;

        if (results.hits && results.hits.hits && results.hits.hits.length && this.term !== '') {
          this.hits = results.hits;
          this.noResults = false;
        } else {
          this.hits = {};
          this.noResults = true;
        }
      },
      (error) => {
        console.log(error);

        this.hits = {};
        this.noResults = true;
        this.updating = false;
      }
    );

    this.route.url.subscribe(() => {
      this.input.nativeElement.value = '';
      this.term = '';
      this.resetSearch();
    });
  }

  ngAfterViewInit() {
    // this.toggles$.subscribe((toggles: Toggle[]) => {
    //   if (toggles) {
    //     const searchToggle = toggles.filter((toggle) => toggle.name === 'search');

    //     if (searchToggle && searchToggle.length) {
    //       this.openState = searchToggle.pop().active;
    //     }

    //     if (this.openState) {
    //       this.setActiveElement();
    //     } else {
    //       this.unsetActiveElement();
    //     }
    //   }
    // });

    this.searchService.showSearch.subscribe((showSearch) => {
      this.openState = showSearch;
      if (this.openState) {
        this.setActiveElement();
      } else {
        this.unsetActiveElement();
      }
    });

    this.keyboardService.getKeyupObservable().subscribe((key) => {
      if (this.openState && ['Escape', 'Esc'].includes(key)) {
        this.toggleSearch();
      } else if (!this.openState && key === 's') {
        if (
          !['TEXTAREA', 'INPUT'].includes(document.activeElement.tagName) &&
          !document.activeElement.getAttribute('contenteditable')
        ) {
          this.toggleSearch();
        }
      }
    });
  }

  executeQuery(filter: any = {}, page: number = null) {
    const query = {
      q: this.term,
    };

    if (query.q.length > 0) {
      this.start = false;
      this.updating = true;
      this.hits = {};
      this.noResults = false;
      this.activePage = 0;

      if (filter.value && filter.value !== 'all') {
        query['type'] = filter.value;
      }

      if (page) {
        this.activePage = page;
        query['page'] = page;
      }

      this.searchTerms.next(query);
    } else {
      this.resetSearch();
    }
  }

  setActiveElement() {
    this.input.nativeElement.focus();
  }

  unsetActiveElement() {
    (document as any).activeElement.blur();
  }

  handleCloseClick() {
    this.toggleSearch();
  }

  toggleSearch() {
    this.searchService.toggleSearch();
  }

  resetSearch() {
    this.hits = {};
    this.activePage = null;
    this.start = true;
    this.updating = false;
    this.noResults = false;
  }

  search(term: string) {
    if (term.length !== this.term.length) {
      this.term = term;
      this.activePage = 0;
      this.executeQuery(this.activeFilter, this.activePage);
    }
  }

  selectFilter(filter) {
    this.activeFilter = filter;
    this.selectPage(0);
    this.executeQuery(this.activeFilter, this.activePage);
  }

  selectPage(page) {
    this.activePage = page;
    this.executeQuery(this.activeFilter, this.activePage);
  }

  removeInputText() {
    this.input.nativeElement.value = '';
    this.hits = {};
    this.start = true;
    this.noResults = false;
  }
}
