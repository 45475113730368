import { Component, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { BreakpointService } from 'app/modules/breakpoint/breakpoint.service';

@Component({
  selector: 'dropdown-convergence',
  templateUrl: './dropdown-convergence.component.html',
  styleUrls: ['./dropdown-convergence.component.scss'],
  animations: [
    trigger('dropdownState', [
      state('inactive', style({ height: 0 })),
      state('active', style({ height: '*' })),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-in')),
    ]),
  ],
})
export class DropdownConvergenceComponent implements OnChanges {
  @Input() buttonTitle: string;
  @Input() dropdownContent: string[];
  @Input() size = 'default';
  @Input() type: string;
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();
  sortTypeSelected = 'Popularity';

  state = 'inactive';
  active = false;
  clicked = false;
  bigScreen = true;

  constructor(private breakpointService: BreakpointService) {}

  ngOnInit() {
    if (!this.type) {
      this.breakpointService.getBreakpointObservable().subscribe((breakpoint) => {
        if (['xs', 'sm', 'md', undefined].indexOf(breakpoint) > -1) {
          this.bigScreen = false;
          this.state = 'active';
        } else {
          this.bigScreen = true;
          this.state = 'inactive';
          this.active = false;
        }
      });
    }
  }

  ngOnChanges() {
    this.setState();
  }

  toggleState() {
    if (!this.bigScreen) {
      return;
    }
    if (!this.clicked) {
      this.active = !this.active;
      this.setState();
    } else {
      this.clicked = false;
    }
  }

  setState() {
    this.state = this.active ? 'active' : 'inactive';
  }

  optionClicked(content: string) {
    this.clicked = true;
    this.active = false;
    this.state = 'inactive';
    this.buttonTitle = content;
    this.optionSelected.emit(this.buttonTitle);
  }

  sortOptionSelected(content, ev) {
    this.active = false;
    this.state = 'inactive';
    this.sortTypeSelected = content;
    this.optionSelected.emit(content);
  }
  clickedOutside(): void {
    if (this.bigScreen) {
      this.active = false;
      this.state = 'inactive';
    }
  }
}
