<div #secondnav>
  <ul class="header-secondary-nav" *ngIf="secondaryNavigation">
    <li *ngFor="let navigationItem of secondaryNavigation; let ind = index" class="-main-menu -hover" (click)="mobileToggleClick(ind);">
      <app-link [to]="navigationItem.path">
        {{navigationItem.title}}
        <icon *ngIf="navigationItem.children && navigationItem.children.length > 0" icon="menu-green-icon" class="-xsm"></icon>
      </app-link>
      <ul *ngIf="navigationItem.children && navigationItem.children.length > 0" class="-child-menu">
        <li *ngFor="let childNavigationItem of navigationItem.children">
          <app-link [to]="childNavigationItem.path">{{childNavigationItem.title}}</app-link>
        </li>
      </ul>
    </li>
  </ul>
</div>