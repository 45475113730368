import { Component, OnInit, Input, ElementRef, AfterViewInit, ViewChild, Renderer2 } from '@angular/core';
import { MenuService } from 'app/services/menu.service';
import { BreakpointService } from '../../modules/breakpoint/breakpoint.service';

@Component({
  selector: 'header-secondary-nav',
  templateUrl: './header-secondary-nav.component.html',
})
export class HeaderSecondaryNavComponent implements OnInit, AfterViewInit {
  @ViewChild('secondnav') secondaryNav: ElementRef<HTMLElement>;
  @Input() type: string;
  secondaryNavigation;
  breakPoint;
  secondNavItems;
  activeMenu = -1;

  constructor(
    public menuService: MenuService,
    private breakpointService: BreakpointService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.menuService.getMenu('secondary-nav-home').subscribe((menus) => {
      this.secondaryNavigation = menus.Items[0].items;
      if (this.secondaryNavigation && this.secondaryNavigation.length > 0) {
        this.secondaryNavigation.forEach((item, index) => {
          this.secondaryNavigation[index].isOpened = false;
        });
      }
    });

    this.breakpointService.getBreakpointObservable().subscribe((breakpoint) => {
      this.breakPoint = breakpoint;
      if (this.secondNavItems && this.secondNavItems.length > 0) {
        if (this.breakPoint === 'xs' || this.breakPoint === 'sm' || this.breakPoint === 'md' || this.breakPoint === undefined) {
          this.secondNavItems.forEach((item) => {
            item.style.cssText = 'cursor: pointer';
            item.classList.remove('-hover');
            this.disableSeccondaryLinks(item);
          });
        } else {
          this.secondNavItems.forEach((item) => {
            item.style.cssText = 'cursor: default';
            item.classList.add('-hover');
            this.enableSeccondaryLinks(item);
            this.mobileClearActive(item);
          });
        }
      }
    });

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.activeMenu >= 0) {
        if (this.breakPoint === 'xs' || this.breakPoint === 'sm' || this.breakPoint === 'md' || this.breakPoint === undefined) {
          if (!this.secondNavItems[this.activeMenu].contains(e.target)) {
            this.secondaryNavigation[this.activeMenu].isOpened = false;
            this.mobileClearActive(this.secondNavItems[this.activeMenu]);
          }
        }
      }
    });
  }

  ngAfterViewInit(): void {
    const secondaryNavE: HTMLElement = this.secondaryNav.nativeElement;
    const observer = new MutationObserver((mutatedEntries) => {
      mutatedEntries.forEach((mutation) => {
        const addedNodes = mutation.addedNodes;
        if (addedNodes.length > 0) {
          this.secondNavItems = this.secondaryNav.nativeElement.querySelectorAll('.-main-menu');
          if (this.breakPoint === 'xs' || this.breakPoint === 'sm' || this.breakPoint === 'md' || this.breakPoint === undefined) {
            this.secondNavItems.forEach((item) => {
              item.style.cssText = 'cursor: pointer';
              item.classList.remove('-hover');
              this.disableSeccondaryLinks(item);
            });
          } else {
            this.secondNavItems.forEach((item) => {
              item.style.cssText = 'cursor: default';
              item.classList.add('-hover');
              this.enableSeccondaryLinks(item);
            });
          }
        }
      });
    });
    observer.observe(secondaryNavE, { childList: true });
  }

  mobileClearActive(item: HTMLElement): void {
    item.classList.remove('active');
    const sm = item.querySelector('ul');
    if (sm) {
      sm.style.cssText = 'visibility: hidden;  opacity: 0;pointer-events: none;';
    }
  }

  disableSeccondaryLinks(item: HTMLElement) {
    const sm = item.querySelector('ul');
    if (sm) {
      sm.style.cssText = 'pointer-events: none;';
    }
  }

  enableSeccondaryLinks(item: HTMLElement) {
    const sm = item.querySelector('ul');
    if (sm) {
      sm.style.cssText = 'pointer-events: unset;';
    }
  }

  mobileSetActive(item: HTMLElement): void {
    item.classList.add('active');
    const sm = item.querySelector('ul');
    if (sm) {
      sm.style.cssText = 'visibility: visible;  opacity: 1; padding: 24px 18px;pointer-events: unset;';
    }
  }

  mobileToggleClick(index: number): void {
    this.activeMenu = index;
    if (this.breakPoint === 'xs' || this.breakPoint === 'sm' || this.breakPoint === 'md' || this.breakPoint === undefined) {
      this.secondNavItems.forEach((item, ind) => {
        this.mobileClearActive(item);
      });

      this.secondaryNavigation[index].isOpened = this.secondaryNavigation[index].isOpened ? false : true;

      if (this.secondaryNavigation[index].isOpened) {
        this.mobileSetActive(this.secondNavItems[index]);
      } else {
        this.mobileClearActive(this.secondNavItems[index]);
      }
    }
  }
}
