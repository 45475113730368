import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { fromEvent, interval, Observable } from 'rxjs';
import { debounce } from 'rxjs/operators';
@Component({
  selector: 'tile-carousel',
  templateUrl: './carousel.component.html',
})
export class CarouselComponent implements AfterViewInit {
  @Input() tiles = [];
  @ViewChild('carouselItem') carouselItem: ElementRef<HTMLElement>;
  @ViewChild('left') left: ElementRef<HTMLElement>;
  @ViewChild('right') right: ElementRef<HTMLElement>;
  @ViewChild('container') container: ElementRef<HTMLElement>;
  carouselItems: NodeListOf<HTMLElement>;
  leftValue = 0;
  oneTileWidth: number;
  resize = fromEvent(window, 'resize');
  newScreen = this.resize.pipe(debounce(() => interval(150)));

  ngAfterViewInit(): void {
    this.setCarousel();
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.newScreen.subscribe(()=> this.setCarousel());
  }

  slideLeft() {
    const scrollWidthAvailable = this.carouselItem.nativeElement.scrollWidth;
    const totalScrollWidth = this.oneTileWidth * this.carouselItems.length;
    if (
      this.carouselItem.nativeElement.children[0].getBoundingClientRect().left <=
      this.left.nativeElement.getBoundingClientRect().left
    ) {
      this.leftValue = this.leftValue + this.oneTileWidth;
      this.carouselItems.forEach((element) => {
        element.style.left = this.leftValue + 'px';
      });

      if (scrollWidthAvailable + this.oneTileWidth == totalScrollWidth) {
        this.left.nativeElement.style.display = 'none';
      }
    }
  }

  slideRight() {
    this.left.nativeElement.style.display = 'block';
    // check if last element of carousel is postitioned before right nav element
    // adding Math.floor for edge cases
    if (
      Math.floor(
        this.carouselItem.nativeElement.children[this.carouselItems.length - 1].getBoundingClientRect().left
      ) >= Math.floor(this.right.nativeElement.getBoundingClientRect().left)
    ) {
      this.leftValue = this.leftValue - this.oneTileWidth;

      this.carouselItems.forEach((element) => {
        element.style.left = this.leftValue + 'px';
      });
    }
  }

  setCarousel() {
    this.carouselItems = this.carouselItem.nativeElement.querySelectorAll('.-slide');
    this.oneTileWidth = this.carouselItems[0].clientWidth;
    let maxTiles = this.container.nativeElement.clientWidth / this.oneTileWidth;
    maxTiles = maxTiles % 1 < 0.5 ? ~~(maxTiles - 1) : ~~maxTiles;
    // below fix for max tile set to 0 leading to infinity value issue
    maxTiles = maxTiles === 0 ? 1 : maxTiles;
    const remainingTileSpace = this.container.nativeElement.clientWidth - this.oneTileWidth * maxTiles;

    // Set responsive size of left and right buttons as well as tile offset
    this.left.nativeElement.style.width = this.right.nativeElement.style.width = `${remainingTileSpace / 2}px`;
    this.leftValue =
      -(Math.floor(this.carouselItems.length / (maxTiles == 1 ? 2 : maxTiles)) * this.oneTileWidth) +
      remainingTileSpace / 2;

    if (this.oneTileWidth * this.carouselItems.length <= this.container.nativeElement.clientWidth) {
      this.left.nativeElement.style.display = this.right.nativeElement.style.display = 'none';
      this.carouselItem.nativeElement.style.justifyContent = 'center';
      this.leftValue = 0;
    } else {
      this.left.nativeElement.style.display = this.right.nativeElement.style.display = 'block';
      this.carouselItem.nativeElement.style.justifyContent = 'unset';
    }

    this.carouselItems.forEach((element) => {
      element.style.left = `${this.leftValue}px`;
    });
  }
}
