<div class="workflow-card -card">
  <div class="workflow-card -status d-flex align-items-stretch">
    <div>
      <icon
        [icon]="iconProperties.iconPath"
        [ngClass]="iconProperties.className">
      </icon>
    </div>
    <div
      *ngIf="order === 'last' ? false : true"
      class="workflow-card -spacer">
    </div>
  </div>
  <div [ngClass]="bottomCardPadding ? 'workflow-card -content -bottom-padding' : 'workflow-card -content'" class="workflow-card -content">
    <p class="workflow-card -content-title -fontMedium -thickFont">
      {{ title }}
      &nbsp;
      <span class="-fontXSmall" *ngIf="tasksComplete > -1;else basicTask">
        ({{ tasksComplete }} of {{ taskCount }} complete)
      </span>
      <ng-template #basicTask>
        <span  class="-fontXSmall" >
          {{ taskCount }}
        </span>
      </ng-template>
    </p>
    <p class="-fontSmall workflow-card -content-description">
      {{ description }}
    </p>
    <link-icon
      iconclass="-sm -colorPrimaryGreen"
      class="workflow-card -link-icon -content-title -thickFont -fontSmall"
      (click)="changeExpandState()"
      [icon]="_childrenCollapsedState.iconPath">
      {{ _childrenCollapsedState.label }}
    </link-icon>
    <div [hidden]="nocontent">
      <div
        #childrenContainer
        class="workflow-card -childrenContainer"
        [@_closed]="_childrenCollapsedState.closed">
        <ng-content></ng-content>
      </div>
   </div>
  </div>
</div>