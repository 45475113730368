<!-- <app-header [route]="pageRoute"></app-header> -->
<div class="page wpdh-page-wrapper">
  <notification *ngIf="isIEOrEdge" title="Warning" class="-colorAccentYellow">
    You are using an unsupported browser. Please switch to a modern browser such as Chrome, Firefox, or Safari for the intended experience.
  </notification>

  <div *ngIf="hasContent">
    <div class="-spec-page-wrapper" *ngIf="meta.template === globals.TEMPLATE_TYPES.REDOC">
      <app-redoc
        [body]="body"
        [categories]="categories"
        [pageRoute]="pageRoute">
      </app-redoc>
      <app-spec-footer></app-spec-footer>
    </div>
    <app-product
      *ngIf="meta.template === globals.TEMPLATE_TYPES.PRODUCT"
      [body]="body"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-product>
    <apis
      *ngIf="meta.template === globals.TEMPLATE_TYPES.APIS_PAGE"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </apis>
    <div class="-doc-page-wrapper" *ngIf="meta.template === globals.TEMPLATE_TYPES.PUBLIC || meta.template === globals.TEMPLATE_TYPES.API_OVERVIEW || meta.template === globals.TEMPLATE_TYPES.DOC_PAGE">
      <div class="-left-nav-container" *ngIf="meta.template !== globals.TEMPLATE_TYPES.PUBLIC" [ngClass]="{'-left-nav-doc-container': meta.template === 'doc-page' , '-hide-sec-nav': !isSecNav, '-overlay-sec-nav': sidebarShow }">
        <small-screen-modal class="-overlay-container" [ngClass]="{'-overlay-slide-in': sidebarShow}" (click)="sidebarShow = !sidebarShow"></small-screen-modal>
        <span class="sidebar-slider" [class.sidebar-slide-in]="sidebarShow" [ngClass]="meta.template === globals.TEMPLATE_TYPES.API_OVERVIEW ? '-no-sec-nav-offset' : isSecNav ? (meta['subscription-group']) ? '-sec-nav-with-release-notes-offset' : '-sec-nav-offset' : '-no-sec-nav-offset'">
          <nav-vertical-updated [ngClass]="{'-doc-page-nav': meta.template === 'doc-page' , '-hide-sec-nav': !isSecNav}" slideNav="slideNav"></nav-vertical-updated>
          <div class="sidebar-opener" (click)="sidebarShow = !sidebarShow">
          </div>
        </span>
      </div>
      <div class="-content" [ngClass]="{'-product-content': meta.template === 'doc-page', '-hide-sec-nav': !isSecNav }" #content>
        <div class="-docpage-content-container">
          <app-public
            *ngIf="meta.template === globals.TEMPLATE_TYPES.PUBLIC"
            [body]="body"
            [meta]="meta"
            [categories]="categories"
            [pageRoute]="pageRoute">
          </app-public>
          <app-api-overview
            *ngIf="meta.template === globals.TEMPLATE_TYPES.API_OVERVIEW"
            [body]="body"
            [meta]="meta"
            [categories]="categories"
            [pageRoute]="pageRoute">
          </app-api-overview>
          <app-doc
            *ngIf="meta.template === globals.TEMPLATE_TYPES.DOC_PAGE"
            [body]="body"
            [categories]="categories"
            [pageRoute]="pageRoute">
          </app-doc>
        </div>    
        <app-footer *ngIf="meta.template !== globals.TEMPLATE_TYPES.PUBLIC" mode="secondary"></app-footer>
      </div>
    </div>
    <app-basic
      *ngIf="meta.template !== globals.TEMPLATE_TYPES.PUBLIC && meta.template !== globals.TEMPLATE_TYPES.PRODUCT && meta.template !== globals.TEMPLATE_TYPES.REDOC && meta.template !== globals.TEMPLATE_TYPES.APIS_PAGE && meta.template !== globals.TEMPLATE_TYPES.API_OVERVIEW && meta.template !== globals.TEMPLATE_TYPES.DOC_PAGE && meta.template !== globals.TEMPLATE_TYPES.HOMEPAGE"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-basic>
    <app-homepage
      *ngIf="meta.template === globals.TEMPLATE_TYPES.HOMEPAGE"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-homepage>
    <app-footer *ngIf="meta.template === globals.TEMPLATE_TYPES.PUBLIC" mode="primary"></app-footer>
  </div>
  
  <!-- <div *ngIf="hasContent" [ngSwitch]="meta.template">
    <app-redoc
      *ngSwitchCase="globals.TEMPLATE_TYPES.REDOC"
      [body]="body"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-redoc> 
    <div [ngClass]="{'doc-page-nav': meta.template === 'doc-page', 'api-page-nav' : meta.template === 'api-overview' }" class="-vertical-nav" [body]="body" [categories]="categories"
      [pageRoute]="pageRoute" #nav>
        <nav-vertical-updated slideNav="slideNav"></nav-vertical-updated>
    </div>
    <app-doc
      *ngSwitchCase="globals.TEMPLATE_TYPES.DOC_PAGE"
      [body]="body"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-doc>
    <app-api-overview
      *ngSwitchCase="globals.TEMPLATE_TYPES.API_OVERVIEW"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-api-overview>
    <app-product
      *ngSwitchCase="globals.TEMPLATE_TYPES.PRODUCT"
      [body]="body"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-product>
    <apis
      *ngSwitchCase="globals.TEMPLATE_TYPES.APIS_PAGE"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </apis>
    <app-homepage
      *ngSwitchCase="globals.TEMPLATE_TYPES.HOMEPAGE"
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-homepage>
    <app-basic
      *ngSwitchDefault
      [body]="body"
      [meta]="meta"
      [categories]="categories"
      [pageRoute]="pageRoute">
    </app-basic> -->
    <!-- <app-lead-gen
      *ngSwitchCase="'contact-sales'">
    </app-lead-gen> -->
    <!-- <app-dashboard
      *ngSwitchCase="'dashboard'">
    </app-dashboard> -->
    <!-- <lead-confirm
      *ngSwitchCase="'contact-confirmation'">
    </lead-confirm> -->
    <!-- <app-four-oh-four
      *ngSwitchCase="'404'">
    </app-four-oh-four>
    <app-four-oh-three
      *ngSwitchCase="'403'">
    </app-four-oh-three> -->
  <!-- </div> -->

  <div *ngIf="!hasContent">Content failed to load!</div>

  <div *ngIf="getapikeyInfoModal">
    <modal 
      [visible]="true" 
      (visibleChange)="closeInfoModal()" 
      [secondaryModal]="true"
      [title]="'Not logged in !'">
      <div>
      <p class="-fontSmall -colorAccentGrey -description-padding">
          You must login to access this feature
      </p>
      <div>
          <wpbutton type="submit" to="/account/login">Login</wpbutton>
          <wpbutton type="submit" to="/account/register">Create a free account</wpbutton>
      </div>
      </div>
    </modal>
  </div>
</div>
