<div class="accordion -main">
  <div class="row">
    <div class="col-sm-12">
      <div class="d-md-flex justify-content-between">
        <div>
          <icon
            (click)="changeExpandState()"
            [icon]="icon"
            class="-colorSupportingBlack -round -backgroundAccentCoolGray -floatLeft accordion -icon">
          </icon>
          <h4 class="accordion -title">{{title}}</h4>
        </div>
        <div>
          <h5 class="accordion -date">{{date}}
          </h5>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row accordion -hiddenRow" [@closed]="closed">
    <div class="col-sm-12">
      <div class="accordion -hiddenContent">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>