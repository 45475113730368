<div
  class="button-big-icon_container"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()">
  <icon
    [icon]="icon"
    [ngClass]="iconClass">
  </icon>
  <!-- <ng-content></ng-content> -->
</div>

<!-- class="-colorPrimaryGreen -lg -round -opacity" -->

