<div *ngIf="!scully && showNotification" [ngClass]="message ? 'message -active':''">
  <div class="-content">
    <content-viewer *ngIf="message" [content]="message" class="margin-reset-children"></content-viewer>
  </div>
  <button-icon 
    class="-close-button" 
    icon="certify" 
    iconcolor="-colorPrimaryGreen"
    iconattributes="-md"
    (click)="handleCloseClick()">
  </button-icon>
</div>
