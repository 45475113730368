/* eslint-disable max-classes-per-file */
import { ShellComponent } from './templates/shell/shell.component';
import { FirstPageLoadService } from './services/firstSiteLoad.service';
import { AnalyticsModule } from './modules/analytics/analytics.module';
// import { NgxHotjarModule } from 'ngx-hotjar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
// Reducers
import toggleReducer from './store/reducer/toggle';
// import voteReducer from './store/reducer/voting';
// import uuidReducer from './store/reducer/uuid';
// import pageMetadataReducer from './store/reducer/pageMetadata';
import { localStorageSync } from 'ngrx-store-localstorage';

import { WaypointModule } from './modules/waypoint/waypoint.module';
import { AppRoutingModule } from './app-routing.module';
import { BreakpointModule } from './modules/breakpoint/breakpoint.module';

import { Globals } from './globals';
import { environment } from '../environments/environment';

// Services
import { ApiService } from './services/api.service';
import { PageService } from './services/page.service';
import { MenuService } from './services/menu.service';
import { MetaService } from './services/meta.service';
import { ServiceDiscoveryService } from './services/service-discovery.service';
import { ConfigService } from './services/config.service';
import { FragmentService } from './services/fragment.service';
import { TouchService } from './services/touch.service';
import { LoaderService } from './services/loader.service';
import { AccountService } from './services/account.service';
import { LocalStorageService } from './services/localStorage.service';
import { CopyService } from './services/copy.service';

// Components
import { EmbeddedComponents, embeddedComponents } from './components/_common/content-viewer/content-viewer';
import { AppComponent } from './app.component';
import { PageComponent } from './pages/page/page.component';
import { NoteComponent } from './components/note/note.component';
import { RedocComponent } from './templates/redoc/redoc.component';
import { BasicComponent } from './templates/basic/basic.component';
import { HomepageComponent } from './templates/homepage/homepage.component';
import { FourOhFourComponent } from './pages/four-oh-four/four-oh-four.component';
import { FourOhThreeComponent } from './pages/four-oh-three/four-oh-three.component';
import { PagerComponent } from './includes/pager/pager.component';
import { ProductComponent } from './templates/product/product.component';
import { LeadGenComponent } from './pages/lead-gen/lead-gen.component';
import { LeadConfirmationComponent } from './pages/lead-confirmation/lead-confirmation.component';
import { FormModule } from './modules/form/form.module';
// import { ScullyLibModule } from '@scullyio/ng-lib';
import { APIsPageComponent } from './pages/apis/apis.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
// import { ScullyInterceptor } from './scully.interceptor';

import { CommonModule } from '@angular/common';
import { DashboardComponent } from './pages/_dashboard/dashboard.component';
import { SharedModule } from './modules/shared/shared.module';
import { CookieNotificationComponent } from './components/cookie-notification/cookie-notification.component';
import { ApiOverviewComponent } from './templates/api-overview/api-overview.component';
import { DocComponent } from './templates/doc-page/doc.component';
import { PublicComponent } from './templates/public/public.component';

// Used for Angular Store
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {
        toggle: {
          replacer: (key, value) => {
            if (value.constructor === Array) {
              return value.filter((el) => {
                return el.name !== 'search';
              });
            }
            return value;
          },
        },
      },
    ],
    rehydrate: true,
  })(reducer);
}

const metaReducers: MetaReducer<any, any>[] = [localStorageSyncReducer];

export const ReducerTokenTest = new InjectionToken('Registered Reducers Test');

export const reducers = {
  toggle: toggleReducer,
};

export function getReducers() {
  return reducers;
}

export const ReducerProvider = [{ provide: ReducerTokenTest, useFactory: getReducers }];

@NgModule({
    declarations: [
        AppComponent,
        PageComponent,
        NoteComponent,
        BasicComponent,
        HomepageComponent,
        FourOhFourComponent,
        FourOhThreeComponent,
        // MomentPipe,
        PagerComponent,
        RedocComponent,
        ProductComponent,
        LeadGenComponent,
        LeadConfirmationComponent,
        APIsPageComponent,
        DashboardComponent,
        ShellComponent,
        CookieNotificationComponent,
        ApiOverviewComponent,
        DocComponent,
        PublicComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({
            appId: 'worldpay-developer-hub',
        }),
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        WaypointModule,
        BreakpointModule,
        // ScullyLibModule,
        FormModule,
        AnalyticsModule,
        StoreModule.forRoot(ReducerTokenTest),
        // NgxHotjarModule.forRoot(environment.hotjar),
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics),
    ],
    providers: [
        ApiService,
        PageService,
        MenuService,
        MetaService,
        EmbeddedComponents,
        Globals,
        ConfigService,
        ReducerProvider,
        FragmentService,
        TouchService,
        LoaderService,
        AccountService,
        LocalStorageService,
        CopyService,
        FirstPageLoadService,
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: ScullyInterceptor,
        //   multi: true,
        // },
    ]
})
export class AppModule {}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    StoreModule.forRoot(ReducerTokenTest, {
      metaReducers,
    }),
    AppModule,
    BrowserModule,
    CommonModule,
    SharedModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
