<ng-container *ngIf="!mode" [ngSwitch]="template">
  <a *ngSwitchCase="'external'" [attr.download]="download ? 'download' : null" class="-external" [href]="to" target="_blank" rel="noopener noreferrer" [attr.disabled]="disabled">
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <icon class="-colorSupportingWhite -sm15" icon="external-link"></icon>
  </a>
  <a *ngSwitchCase="'newTab'" [attr.download]="download ? 'download' : null" class="-external" [routerLink]="to" target="_blank" rel="noopener noreferrer" [attr.disabled]="disabled">
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <icon class="-colorSupportingWhite -sm15" icon="external-link"></icon>
  </a>
  <a *ngSwitchCase="'internal'" [routerLink]="url" [queryParams]="tree.queryParams" [fragment]="tree.fragment" [attr.disabled]="disabled">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
  <a *ngSwitchCase="'falseInternal'" [href]="to">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
  <a *ngSwitchCase="'noRoute'" [class]="display">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-container *ngIf="mode && mode === 'requestnow'">
  <a [class]="display">
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <icon *ngIf="requestnowProcessing" icon="refresh" class="-refresh -infinite-spin -sm15 -colorPrimaryGreen" ></icon>
    <icon *ngIf="requestnowProcessing === false" icon="checkmark" class="-refresh -sm15 -colorPrimaryGreen" ></icon>
  </a>
</ng-container>

<ng-container *ngIf="mode && mode === 'getapikey'">
  <a [class]="display">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>