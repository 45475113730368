<div class="dropdown -parent -multiselect" clickOutside (clickOutside)="clickedOutside()" >
  <div class="-header" *ngIf="buttonTitle" (click)="toggleState()">
    <div class="-button">{{ buttonTitle }}</div>   
    <div class="-icon-container">
      <span *ngIf="selectionCount > 0 && bigScreen">({{selectionCount}})</span>
      <icon class="-colorSupportingWhite -xsm" [class.dropdown-rotate-icon]="active" icon="dropdown"></icon>
    </div> 
  </div>
  <div class="-content" [@dropdownMultiselectState]="state" #dropdownFilterOption>
    <div class="content-padding" >
      <div class="-content-option" *ngFor="let content of dropdownContent">
        <label class="-group-element">
          <input type="checkbox" [value]="content.name" (click)="optionChecked(content, $event)">
          <span class="-checkbox-container">
            <span class="-group-element-checkbox -checkbox-blue"></span>
          </span>
          <span class="-checkbox-text">
            {{content.name}}<span class="content-count" *ngIf="showCounts">({{content.count}})</span>
          </span>        
        </label>
      </div>
    </div>
  </div>
</div>