import { MetaService } from 'app/services/meta.service';
import { LoaderService } from './../../services/loader.service';
import { Input, OnInit, Directive, OnDestroy } from '@angular/core';
import { FragmentService } from '../../services/fragment.service';
import { Subscription } from 'rxjs';

// const ANIMATION_DURATION = 250;

@Directive()
export class BaseComponent implements OnInit, OnDestroy {
  @Input() body: any;
  @Input() meta: any = {};
  @Input() categories: any = {};
  @Input() pageRoute: string;

  bodyClasses: string[] = ['wpdh-body-styles'];
  scrolling = false;
  scrollSubcription: any;
  // initialBool = false;
  subscription = new Subscription();

  constructor(
    public fragmentService: FragmentService,
    public loaderService: LoaderService,
    public metaService: MetaService
  ) {}

  handleDocRendered() {
    if (this.scrollSubcription) {
      this.scrolling = false;
      this.scrollSubcription.unsubscribe();
    }

    this.scrollSubcription = this.fragmentService.getEvents();

    // Wait until metadata has been processed to remove loader
    this.metaService.metadata
      .subscribe((metadata) => {
        if (!metadata['vertical-nav']) {
          this.loaderService.loadingContent.emit(false);
        }
      })
      .unsubscribe();
  }

  ngOnInit() {
    this.subscription.add(
      this.metaService.metadata.subscribe((metadata) => {
        this.meta = metadata;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
