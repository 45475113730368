import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WorkflowIconService {
  constructor() {}

  changeIcon(status: string, type: string): any {
    const iconProperties = {
      iconPath: '',
      className: '',
    };

    switch (status) {
      case 'checkmark':
        iconProperties.iconPath = status;
        iconProperties.className = '-colorTintPrimaryBlack -backgroundPrimaryGreen -round -roundBold';
        break;
      case 'active':
        type === 'task' ? iconProperties.iconPath = 'shape-exclamation' : status;
        iconProperties.className =
          type === 'task'
            ? '-colorTintPrimaryBlack'
            : '-colorSupportingWhite -backgroundSupportingWhite -round';
        break;
      case 'inactive':
        iconProperties.iconPath = status;
        iconProperties.className =
          type === 'task'
            ? '-colorSupportingWhite -backgroundShadePrimaryBlackS50 -border -round'
            : '-colorTintPrimaryBlackT60 -colorIconCircleGray -round';
        break;
      case '':
      case 'green':
        iconProperties.iconPath = 'inactive';
        iconProperties.className = '-colorTintPrimaryBlack -backgroundPrimaryGreen -round -roundBold';
        break;
      default:
        iconProperties.iconPath = 'inactive';
        iconProperties.className =
          type === 'task'
            ? '-backgroundTintPrimaryBlackT60 -round'
            : '-colorTintPrimaryBlackT60 -backgroundTintPrimaryBlackT60 -round';
        break;
    }
    return iconProperties;
  }
}
