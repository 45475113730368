<button-icon 
  icon="dropup" 
  class="back-to-top"
  iconcolor="-colorPrimaryGreen"
  backgroundcolor="-backgroundSupportingWhite"
  backgroundhovercolor="-backgroundTintPrimaryBlackT60"
  iconattributes="-md -round -opacity"
  [@buttonVisibilityState]="buttonState"
  (click)="handleClick($event)">
</button-icon>
<span class="hidden">Back to top</span>
