<div class="header" [ngClass]="{ 'transparentBG': transparentBackground }">
  <div class="nav -container d-flex" [ngClass]="metadata && metadata.template && metadata.template === 'homepage' ? '-border' : ''">
    <div class="nav -expand-button">
      <button-icon 
        icon="hamburger" 
        iconhovercolor="-colorSupportingWhite"
        iconcolor="-colorSupportingWhite"
        *ngIf="!smallMenuState"
        (click)="mobileToggleClick()">
      </button-icon>
      <button-icon 
        icon="close" 
        iconhovercolor="-colorSupportingWhite"
        iconcolor="-colorSupportingWhite"
        *ngIf="smallMenuState"
        (click)="mobileToggleClick()">
      </button-icon>
    </div>
    <div class="nav -logo-padding">
      <a [routerLink]="'/'">
        <div class="nav -logo">
          <img src="/assets/images/site-logo/FIS-developer-logo.svg" alt="FIS Logo" />
        </div>
      </a>
    </div>

    <div class="nav -wrapper">
      <div class="nav -menus d-flex">
        <app-main-nav (menuReady)="prepareSmallMenu($event)"></app-main-nav> 
        <app-functional-nav (functionalNavReady)="prepareSmallFunctionalNav($event)" (accPullDown)="accountPullDownHandler($event)" [isToggle]="toggleAccountMenu"></app-functional-nav>
      </div>
    </div>
  </div>
  <div *ngIf="metadata && metadata.template && metadata.template === 'homepage'">
    <header-secondary-nav></header-secondary-nav>
  </div>
  <div *ngIf="metadata && metadata.template && (metadata.template === 'doc-page' || (metadata.template === 'redoc' && metadata['secondary-nav'])) && (metadata['sec-nav'] === undefined ? true : metadata['sec-nav'])">
    <docheader-secondary-nav></docheader-secondary-nav>
  </div>
</div>
<div #smallScreenMenuHolder>
  <small-screen-modal class="-menu-holder -small-screen" [ngClass]="smallMenuState ? '-open' : ''">
    <div class="-menu-wrapper">
      <div class="-functional-nav" *ngIf="functionalMenu && functionalMenu.length > 0">
        <a class="">
          <app-search-box [isSmall]="true"></app-search-box>
        </a>
        <a *ngFor="let icon of functionalMenu" [routerLink]="'/' + icon?.path">
          <button-icon
            iconattributes="-sm15"
            [icon]="icon.icon" 
            iconhovercolor="-colorAccentLightBlue"
            [iconcolor]="router.url.includes(icon.path) ? '-colorAccentLightBlue' : '-colorSupportingWhite'">
          </button-icon>
        </a>
      </div>
      <div class="-menu-back" (click)="navigateMainMenu()" *ngIf="menusNavigationState">
        <button-icon
          icon="singlearrow-left" 
          iconattributes="-sm15"
          iconhovercolor="-colorSupportingWhite"
          iconcolor="-colorSupportingWhite">
        </button-icon>
        Back
      </div>
      <div class="-menus" *ngIf="menusState">
        <div *ngFor="let menuItem of mainMenu.right; let menuindex = index" class="-menu" [ngClass]="{'-parent-menu-container': (menuItem.children && menuItem.children.length > 0)}">
          <ng-container *ngIf="menuItem.children && menuItem.children.length > 0">
            <h5>{{ menuItem.title }}</h5>
          </ng-container>
          <ng-container *ngIf="!menuItem.children">
            <a
            *ngIf="!menuItem.external"
            [routerLink]="menuItem.path">
              {{ menuItem.title }}
          </a>
          <a
            *ngIf="menuItem.external"
            href="{{ menuItem.path }}"
            target="_blank" 
            rel="noopener noreferrer">
              {{ menuItem.title }}
          </a>
          </ng-container>
          <div class="-child-menus" *ngIf="menuItem.children && menuItem.children.length > 0">
              <div class="-child-menu">
                <app-link [to]="mainMenu.left.path">{{ mainMenu.left.title }}</app-link>
              </div>
              <div class="-child-menu" *ngFor="let childMenuItem of menuItem.children; let childMenuIndex = index" (click)="showMenuContent(menuindex, childMenuIndex)">
                <span>{{childMenuItem.title}}</span>
                <button-icon
                  *ngIf="childMenuItem.columnOne || childMenuItem.columnThree || childMenuItem.columnTwo" 
                  icon="singlearrow-right" 
                  iconattributes="-sm15"
                  iconhovercolor="-colorSupportingWhite"
                  iconcolor="-colorSupportingWhite">
                </button-icon>
              </div>
          </div>
        </div>
      </div>

      <div class="-menu-content" *ngIf="menusContentState">
        <div class="-menu-head">
          <h5>{{ menuContent?.title }}</h5>
        </div>
        <div class="-menu-body">
          <ng-container *ngIf="menuContent?.columnOne && menuContent?.columnOne?.children && menuContent?.columnOne?.children?.length > 0">
            <ng-container *ngTemplateOutlet="column; context: {column: menuContent.columnOne}"></ng-container>
          </ng-container>
          <ng-container *ngIf="menuContent?.columnTwo && menuContent?.columnTwo?.children && menuContent?.columnTwo?.children?.length > 0">
            <ng-container *ngTemplateOutlet="column; context: {column: menuContent.columnTwo}"></ng-container>
          </ng-container>
          <ng-container *ngIf="menuContent?.columnThree && menuContent?.columnThree?.children && menuContent?.columnThree?.children?.length > 0">
            <ng-container *ngTemplateOutlet="column; context: {column: menuContent.columnThree}"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </small-screen-modal>
</div>

<ng-template #column let-column="column">
  <div class="-menu-column">
    <h5>{{ column.title }}</h5>
    <div class="-menu-column-content">
      <ng-container *ngFor="let childLinks of column?.children">
        <ng-container *ngFor="let link of childLinks.links">
          <div class="-menu-column-item">
            <app-link [to]="link.path" [externaloverride]="link.external">{{ link?.title }}</app-link>
            <ng-container *ngIf="link?.pdf"><p class="-pdf">PDF</p></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>