import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountData } from 'app/models/accountData.interface';
import { LocalStorageService } from '../services/localStorage.service';

@Injectable()
export class AccountService {
  accountInfo: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService
  ) {}

  getAccount() {
    return new Observable((observer) => {
      this.apiService.get('/User').subscribe((data: AccountData) => {
        if (!this.localStorageService.getItem('groups')) {
          this.localStorageService.setItem('groups', data.groups || '');
        }
        this.accountInfo.next(data);
        observer.next(data);
      });
    });
  }

  updateAccount(data: any) {
    // return this.apiService.post('/User', data);

    return new Observable((observer: any) => {
      this.apiService.post('/User', data).subscribe((accountResponse: any) => {
        this.accountInfo.next({});
        this.getAccount().subscribe();
        observer.next(accountResponse);
        observer.complete();
      });
    });
  }

  deleteAccount() {
    return this.apiService.delete('/User');
  }
}
