import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'rest-verb-box',
  templateUrl: './rest-verb-box.component.html',
  styleUrls: ['./rest-verb-box.component.scss'],
})
export class RestVerbBoxComponent {
  @Input() verb = '';
  filterColors = {
    POST: '-colorAccentTeal',
    GET: '-colorAccentLightPurple',
    DELETE: '-colorAccentRed',
    PATCH: '-colorAccentTeal',
    PUT: '-colorAccentLightBlue',
  };

  @HostBinding('className') get backgroundClass() {
    return this.filterColors[this.verb];
  }

  constructor() {}

}
