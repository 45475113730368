import { HeaderTabsComponent } from './../../components/_header-dropdowns/header-tabs/header-tabs.component';
import { WorkflowService } from '../../services/workflow.service';
import { NavVerticalService } from '../../services/nav-vetical.service';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { CardChainComponent } from '../../components/_cards/card-chain/card-chain.component';
import { WpButtonComponent } from '../../components/_common/wp-button/wp-button.component';
import { LinkComponent } from '../../components/_common/link/link.component';
import { LinkIconComponent } from '../../components/_common/link-icon/link-icon.component';
import { IconComponent } from '../../components/_common/icon/icon.component';
import { IconTileComponent } from 'app/components/_common/icon-tile/icon-tile.component';
import { ContentViewerComponent } from 'app/components/_common/content-viewer/content-viewer';
import { ClickThrottleDirective } from 'app/directives/click-throttle.directive';
import { ApiCollectionComponent } from 'app/components/_common/api-collection/api-collection.component';
import { PillComponent } from 'app/components/_common/pill/pill.component';
import { PillHolderCollapsableComponent } from 'app/components/_common/pill-holder-collapsable/pill-holder-collapsable.component';
import { TabBlockComponent } from 'app/components/_common/tab-block/tab-block.component';
import { TabBlockItemComponent } from 'app/components/_common/tab-block-item/tab-block-item.component';
import { LoaderComponent } from 'app/components/_common/loader/loader.component';
import { BreadcrumbsComponent } from 'app/components/_common/breadcrumbs/breadcrumbs.component';
import { CardBasicComponent } from 'app/components/_cards/card-basic/card-basic.component';
import { WpTableComponent } from 'app/components/wp-table/wp-table.component';
import { SectionCollapsableComponent } from 'app/components/_common/section-collapsable/section-collapsable.component';
import { APIVersionsComponent } from 'app/components/_common/api-versions/api-versions.component';
import { CardCapabilityComponent } from 'app/components/_cards/card-capability/card-capability.component';
import { SectionCollapsableParentComponent } from 'app/components/_common/section-collapsable-parent/section-collapsable-parent.component';
import { AccordionComponent } from 'app/components/accordion/accordion.component';
import { AccordionConvergenceComponent } from 'app/components/accordion-convergence/accordion-convergence.component';
import { NavVerticalComponent } from 'app/components/_common/nav-vertical/nav-vertical.component';
import { NavVerticalUpdatedComponent } from 'app/components/_common/nav-vertical-updated/nav-vertical-updated.component';
import { APILeftMenuComponent } from 'app/components/_common/api-left-menu/api-left-menu.component';
import { WorkflowCardConvergenceComponent } from 'app/components/_common/workflow-card-convergence/workflow-card-convergence.component';
import { WorkflowComponent } from 'app/components/_common/workflow/workflow.component';
import { WorkflowTaskConvergenceComponent } from 'app/components/_common/workflow-task-convergence/workflow-task-convergence.component';
import { DropdownComponent } from '../../components/_common/dropdown/dropdown.component';
import { DropdownConvergenceComponent } from '../../components/_common/dropdown-convergence/dropdown-convergence.component';
import { MultiSelectDropdownComponent } from '../../components/_common/multi-select-dropdown/multi-select-dropdown.component';
import { ModalComponent } from '../../components/_common/modal/modal.component';
import { GuidedModalComponent } from '../../components/_common/guided-modal/guided-modal.component';
import { WpButtonIconComponent } from '../../components/wp-button-icon/wp-button-icon.component';
import { MastheadComponent } from '../../components/masthead/masthead.component';
import { NotificationsComponent } from '../../components/notifications/notifications.component';
import { GridComponent } from '../../components/grid/grid.component';
import { GridColumnComponent } from '../../components/grid-column/grid-column.component';
import { MastheadCenteredComponent } from '../../components/masthead-centered/masthead-centered.component';
import { MastheadCTAComponent } from '../../components/masthead-cta/masthead-cta.component';
import { CardAPIComponent } from '../../components/_cards/card-api/card-api.component';
import { CardProductComponent } from '../../components/_cards/card-product/card-product.component';
import { RestVerbBoxComponent } from '../../components/_common/rest-verb-box/rest-verb-box.component';
import { TooltipComponent } from 'app/components/_common/tooltip/tooltip.component';
import { FooterComponent } from 'app/includes/footer/footer.component';
import { SpecFooterComponent } from 'app/includes/footer/spec-footer/spec-footer.component';
import { HeaderComponent } from 'app/includes/header/header.component';
import { CodeBlockItemComponent } from 'app/components/code-block-item/code-block-item.component';
import { CodeBlockComponent } from 'app/components/code-block/code-block.component';
import { DiagramConvergenceComponent } from 'app/components/diagram-convergence/diagram-convergence.component';
import { FileService } from 'app/services/file.service';
import { FunctionalNavComponent } from 'app/includes/functional-nav/functional-nav.component';
import { MainNavComponent } from 'app/includes/main-nav/main-nav.component';
import { SearchBoxComponent } from 'app/includes/search-box/search-box.component';
import { SearchPagerComponent } from 'app/includes/search-pager/search-pager.component';
import { SearchViewComponent } from 'app/includes/search-view/search-view.component';
import { SearchWindowComponent } from 'app/includes/search-window/search-window.component';
import { SearchService } from 'app/services/search.service';
import { KeyboardService } from 'app/services/keyboard.service';
import { CodeblockService } from 'app/services/codeblock.service';
import { CognitoService } from 'app/services/cognito.service';
import { ModalMatchService } from 'app/services/modal-match.service';
import { AccountDropdownComponent } from 'app/components/_header-dropdowns/header-account-dropdown/account-dropdown.component';
import { TokenService } from 'app/services/token.service';
import { BackToTopComponent } from 'app/components/back-to-top/back-to-top.component';
import { CardComponent } from 'app/components/_cards/card/card.component';
import { CardApiConvergenceComponent } from 'app/components/_cards/card-api-convergence/card-api-convergence.component';
import { SecondaryMastheadComponent } from 'app/components/secondary-masthead/secondary-masthead.component';
import { SmallIconTileComponent } from 'app/components/_common/small-icon-tile/small-icon-tile.component';
import { CarouselComponent } from 'app/components/_common/carousel/carousel.component';
import { SmallImageCardComponent } from 'app/components/_cards/small-image-card/small-image-card.component';
import { MastheadGradientComponent } from 'app/components/masthead-gradient/masthead-gradient.component';
import { MastheadBasicComponent } from 'app/components/masthead-basic/masthead-basic.component';
import { TestimonialComponent } from 'app/components/testimonial/testimonial.component';
import { TestimonialItemComponent } from 'app/components/testimonial/testimonial-item/testimonial-item.component';
import { SlidingButtonComponent } from 'app/components/sliding-button/sliding-button.component';
import { ArticleImageCardComponent } from 'app/components/article-image-card/article-image-card.component';
import { DocIndexComponent } from 'app/components/doc-index/doc-index.component';
import { HeaderMenuDropdownComponent } from 'app/components/_header-dropdowns/header-menu-dropdown/header-menu-dropdown.component';
import { BaseCardComponent } from 'app/components/_cards/base-card/base-card.component';
import { HeaderSecondaryNavComponent } from 'app/components/header-secondary-nav/header-secondary-nav.component';
import { DocheaderSecondaryNavComponent } from 'app/components/docheader-secondary-nav/docheader-secondary-nav.component';
import { WorkflowCardComponent } from 'app/components/_common/workflow-card/workflow-card.component';
import { WorkflowTaskComponent } from 'app/components/_common/workflow-task/workflow-task.component';
import { DiagramComponent } from 'app/components/diagram/diagram.component';
import { ReleaseNotesSubscriptionComponent } from 'app/components/releasenotes-subscription/releasenotes-subscription.component';
import { APIVersionsConvergenceComponent } from 'app/components/_common/api-versions-convergence/api-versions-convergence.component';
import { AccordionConvergenceNewComponent } from 'app/components/accordion-convergence-new/accordionConvergence.component';
import { ClickOutsideDirective } from 'app/directives/clickoutside-dropdown.directive';
import { APIPaginationComponent } from 'app/components/api-list-pagination/api-pagination.component';
import { SmallScreenModalComponent } from 'app/components/small-screen-modal/small-screen-modal.component';
@NgModule({
    declarations: [
        ContentViewerComponent,
        LinkComponent,
        LinkIconComponent,
        IconComponent,
        IconTileComponent,
        WpButtonComponent,
        CardChainComponent,
        ClickThrottleDirective,
        ClickOutsideDirective,
        ApiCollectionComponent,
        CardAPIComponent,
        PillComponent,
        PillHolderCollapsableComponent,
        TabBlockComponent,
        TabBlockItemComponent,
        LoaderComponent,
        MastheadCTAComponent,
        MastheadCenteredComponent,
        GridColumnComponent,
        GridComponent,
        NotificationsComponent,
        MastheadComponent,
        MastheadGradientComponent,
        MastheadBasicComponent,
        WpButtonIconComponent,
        BreadcrumbsComponent,
        CardBasicComponent,
        CardProductComponent,
        SectionCollapsableComponent,
        WpTableComponent,
        APIVersionsComponent,
        ModalComponent,
        GuidedModalComponent,
        CardCapabilityComponent,
        SectionCollapsableParentComponent,
        AccordionComponent,
        AccordionConvergenceComponent,
        NavVerticalComponent,
        NavVerticalUpdatedComponent,
        APILeftMenuComponent,
        DropdownComponent,
        DropdownConvergenceComponent,
        MultiSelectDropdownComponent,
        WorkflowCardConvergenceComponent,
        WorkflowComponent,
        WorkflowTaskConvergenceComponent,
        RestVerbBoxComponent,
        TooltipComponent,
        FooterComponent,
        SpecFooterComponent,
        HeaderComponent,
        CodeBlockComponent,
        CodeBlockItemComponent,
        DiagramConvergenceComponent,
        MainNavComponent,
        FunctionalNavComponent,
        SearchBoxComponent,
        SearchWindowComponent,
        SearchViewComponent,
        SearchPagerComponent,
        AccountDropdownComponent,
        BackToTopComponent,
        CardComponent,
        CardApiConvergenceComponent,
        SecondaryMastheadComponent,
        SmallIconTileComponent,
        CarouselComponent,
        SmallImageCardComponent,
        TestimonialComponent,
        TestimonialItemComponent,
        SlidingButtonComponent,
        ArticleImageCardComponent,
        BaseCardComponent,
        DocIndexComponent,
        HeaderMenuDropdownComponent,
        HeaderTabsComponent,
        BaseCardComponent,
        HeaderSecondaryNavComponent,
        DocheaderSecondaryNavComponent,
        WorkflowCardComponent,
        WorkflowTaskComponent,
        DiagramComponent,
        ReleaseNotesSubscriptionComponent,
        APIVersionsConvergenceComponent,
        APIPaginationComponent,
        SmallScreenModalComponent,
        AccordionConvergenceNewComponent
    ],
    imports: [CommonModule, RouterModule],
    exports: [
        ContentViewerComponent,
        LinkComponent,
        IconComponent,
        LinkIconComponent,
        IconTileComponent,
        WpButtonComponent,
        CardChainComponent,
        ClickThrottleDirective,
        ClickOutsideDirective,
        ApiCollectionComponent,
        CardAPIComponent,
        CardApiConvergenceComponent,
        PillComponent,
        PillHolderCollapsableComponent,
        TabBlockComponent,
        TabBlockItemComponent,
        LoaderComponent,
        MastheadCTAComponent,
        MastheadCenteredComponent,
        MastheadGradientComponent,
        MastheadBasicComponent,
        GridColumnComponent,
        GridComponent,
        NotificationsComponent,
        MastheadComponent,
        WpButtonIconComponent,
        BreadcrumbsComponent,
        CardBasicComponent,
        CardProductComponent,
        SectionCollapsableComponent,
        WpTableComponent,
        APIVersionsComponent,
        ModalComponent,
        GuidedModalComponent,
        CardCapabilityComponent,
        SectionCollapsableParentComponent,
        AccordionComponent,
        AccordionConvergenceComponent,
        AccordionConvergenceNewComponent,
        NavVerticalComponent,
        NavVerticalUpdatedComponent,
        APILeftMenuComponent,
        DropdownComponent,
        DropdownConvergenceComponent,
        MultiSelectDropdownComponent,
        WorkflowCardConvergenceComponent,
        WorkflowComponent,
        WorkflowTaskConvergenceComponent,
        WorkflowCardComponent,
        WorkflowTaskComponent,
        RestVerbBoxComponent,
        TooltipComponent,
        FooterComponent,
        SpecFooterComponent,
        HeaderComponent,
        CodeBlockComponent,
        CodeBlockItemComponent,
        DiagramConvergenceComponent,
        MainNavComponent,
        FunctionalNavComponent,
        AccountDropdownComponent,
        SearchWindowComponent,
        SearchBoxComponent,
        SearchWindowComponent,
        SearchViewComponent,
        SearchPagerComponent,
        BackToTopComponent,
        CardComponent,
        SecondaryMastheadComponent,
        SmallIconTileComponent,
        CarouselComponent,
        SmallImageCardComponent,
        TestimonialComponent,
        TestimonialItemComponent,
        SlidingButtonComponent,
        ArticleImageCardComponent,
        BaseCardComponent,
        DocIndexComponent,
        HeaderMenuDropdownComponent,
        HeaderTabsComponent,
        BaseCardComponent,
        HeaderSecondaryNavComponent,
        DocheaderSecondaryNavComponent,
        DiagramComponent,
        ReleaseNotesSubscriptionComponent,
        APIVersionsConvergenceComponent,
        APIPaginationComponent,
        SmallScreenModalComponent,
    ],
    providers: [
        NavVerticalService,
        WorkflowService,
        FileService,
        SearchService,
        CodeblockService,
        CognitoService,
        ModalMatchService,
        TokenService,
        KeyboardService,
    ]
})
export class SharedModule {}