<ng-container [ngSwitch]="template">
  <a 
    *ngSwitchCase="'external'" 
    [ngClass]="linkclass ? linkclass : ''" 
    [href]="to" 
    target="_blank"
    rel="noopener noreferrer" 
    [attr.disabled]="disabled">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
  <a 
    *ngSwitchCase="'internal'" 
    [ngClass]="linkclass ? linkclass : ''" 
    [routerLink]="url"
    [queryParams]="tree.queryParams" 
    [fragment]="tree.fragment" 
    [attr.disabled]="disabled">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
  <a *ngSwitchCase="'falseInternal'" [ngClass]="linkclass ? linkclass : ''" [href]="to">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
  <a *ngSwitchCase="'noRoute'" [ngClass]="linkclass ? linkclass : ''">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
  <a 
    *ngSwitchCase="'newTab'" 
    [ngClass]="linkclass ? linkclass : ''" 
    [routerLink]="to"
    target="_blank" 
    rel="noopener noreferrer" 
    [attr.disabled]="disabled">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<div class="link-icon -parent">
  <ng-template #content>
    <div class="link-icon -parent -text">
      <ng-content></ng-content>
    </div>
    <icon [icon]="icon" [ngClass]="iconclass !== undefined ? iconclass : '-colorPrimaryGreen'">
    </icon>
  </ng-template>
</div>