import { Component, ElementRef, ViewChild, Input, HostListener, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LinkComponent } from '../link/link.component';
import { CognitoService } from '../../../services/cognito.service';
import { EmailService } from '../../../services/email.service';
import { DocPageNotificationService } from 'app/services/docpage-notification.service';
import { PageService } from 'app/services/page.service';

@Component({
  selector: 'wpbutton',
  templateUrl: './wp-button.component.html',
  styleUrls: ['./wp-button.component.scss'],
})
export class WpButtonComponent extends LinkComponent implements OnDestroy {
  @Input() mode;
  requestnowProcessing;
  subscription: Subscription;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public cognitoService: CognitoService,
    private emailService: EmailService,
    private notificationService: DocPageNotificationService,
    private pageService: PageService
  ) {
    super(router, route, cognitoService);
  }

  @ViewChild('contentWrapper') content: ElementRef;

  @HostListener('click', ['$event.target'])
  clickHandler() {
    if (this.mode === 'getapikey') {
      if (!this.cognitoService.getUserInfo()) this.pageService.getApiKeyConfirmEvent.emit();
      else this.router.navigate(['/account/self-service']);
    }
    if (this.mode === 'requestnow') {
      if (!this.cognitoService.getUserInfo()) {
        this.router.navigate(['/account/login']);
        return;
      }

      if (this.requestnowProcessing === false || this.requestnowProcessing === true) {
        return;
      }
      this.subscription = new Subscription();
      this.requestnowProcessing = true;
      this.subscription.add(
        this.emailService.sendMail().subscribe(
          (resp: any) => {
            this.requestnowProcessing = false;
            let notification;
            if (resp && resp.error) {
              notification = {
                title: 'Error',
                description: 'Sorry, something went wrong processing your request - please try again later',
                status: 'failure',
                show: true,
              };
            } else {
              notification = {
                title: 'Success',
                description:
                  'Your request has been received. You will receive an email from Developer Engine when completed.',
                status: 'success',
                show: true,
              };
            }
            this.notificationService.sendNotification(notification);
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          },
          () => {
            this.requestnowProcessing = false;
            const notification = {
              title: 'Error',
              description: 'Sorry, something went wrong processing your request - please try again later',
              status: 'failure',
              show: true,
            };
            this.notificationService.sendNotification(notification);
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
