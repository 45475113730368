import { Component, OnInit, Renderer2, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
// import { isScullyRunning } from '@scullyio/ng-lib';
import { toggle } from './store/reducer/toggle';
import { Globals } from './globals';
import { AppState } from './types/app-state';
import { ChatService } from './services/chat.service';
import { MetaService } from './services/meta.service';
import { LoaderService } from './services/loader.service';
import { CognitoService } from './services/cognito.service';
import { SearchService } from 'app/services/search.service';
import { ConfigService } from './services/config.service';
import { AccountService } from './services/account.service';
import { LocalStorageService } from './services/localStorage.service';
import { environment } from 'environments/environment';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let hj: any;
declare let pendo: any;
declare function pendoIntialize(apiKey): void;
declare function ga4TagInitialise(googleTagManager): void;
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked, AfterViewInit {
  toggles$: Observable<any[]>;
  activeToggleClasses: string[] = [];
  message: string;
  subscription: Subscription = new Subscription();
  // Switch to false for scully
  loadingContent = environment.envName === 'development' ? false : true;

  constructor(
    private store: Store<AppState>,
    protected renderer: Renderer2,
    public globals: Globals,
    public configService: ConfigService,
    public chatService: ChatService,
    public loaderService: LoaderService,
    private changeRef: ChangeDetectorRef,
    private cognitoService: CognitoService,
    private searchService: SearchService,
    private accountService: AccountService,
    private localStorageService: LocalStorageService,
    private metaService: MetaService,
    private router: Router
  ) {
    this.toggles$ = this.store.pipe(select('toggle'));
  }

  ngOnInit() {
    // if (environment.googleAnalytics) {
    //   const googleAnalyticsScript = this.renderer2.createElement('script');
    //   googleAnalyticsScript.id = 'google-analytics';
    //   googleAnalyticsScript.defer = true;
    //   googleAnalyticsScript.type = 'text/javascript';
    //   googleAnalyticsScript.nonce = '56gibS69Ljrqq6';
    //   if (environment.envName === 'prod') {
    //     googleAnalyticsScript.src = '../assets/google-analytics/18344670-2';
    //   } else {
    //     googleAnalyticsScript.src = '../assets/google-analytics/18344670-1';
    //   }
    // }
    this.addMarketoTrackingCode();
    if (environment.chatEnabled && environment.chatUrl) {
      this.setWhosonChat();
    }
    if (environment.pendoApiKey) {
      pendoIntialize(environment.pendoApiKey);
    }

    if (environment.googleTagManager) {
      ga4TagInitialise(environment.googleTagManager);
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = "https://www.googletagmanager.com/ns.html?id=" + environment.googleTagManager;
      document.body.appendChild(iframe);
    }

    // Cognito Session
    this.cognitoService.createSession();

    // Loader subscription
    this.subscription.add(
      this.loaderService.loadingContent.subscribe((loading: boolean) => {
        // console.trace(loading);
        this.loadingContent = loading;
      })
    );

    this.subscription.add(
      this.searchService.showSearch.subscribe((bool) => {
        if (bool) {
          this.renderer.addClass(document.documentElement, 'is-scroll-disabled');
        } else {
          this.renderer.removeClass(document.documentElement, 'is-scroll-disabled');
        }
      })
    );

    let pendoInterval;
    const pendoContext = () => {
      if (this.cognitoService.getUserInfo()) {
        this.accountService.accountInfo.subscribe((accountInfo) => {
          if (accountInfo && accountInfo.email) {
            this.setPendoContext(accountInfo.email);
          }
        });
      } else {
        try {
          clearInterval(pendoInterval);
          pendo.clearSession();
          this.setPendoContext();
        } catch (err) {
          //uncomment below if needed, not required as of now
          //console.log(err);
        }
      }
    };

    if (environment.pendoApiKey) {
      pendoInterval = setInterval(pendoContext, 2000);
    }

    // this.addHotjar();
  }

  setWhosonChat() {
    let chatBtn: HTMLElement;
    const whosonScriptId = 'whoson-script';
    const buttonScriptId = 'chat-button-script';
    const showButton = (data) => {
      const encryptedData = data || '';
      const whosOnTrackScript = document.createElement('script');
      whosOnTrackScript.id = buttonScriptId;
      whosOnTrackScript.text =
        encryptedData && typeof encryptedData === 'string'
          ? `sWOAddVariable("data","${encryptedData}");`
          : `if(sWOTrackPage)sWOTrackPage();`;
      document.body.appendChild(whosOnTrackScript);
    };
    const getChatToken = () => {
      this.chatService.getEncryptedData().subscribe(
        (data: string) => {
          showButton(data);
        },
        () => {}
      );
    };
    const chatButtonBehavior = (observer: MutationObserver) => {
      chatBtn = document.querySelector('#wo_online_image');
      if (chatBtn) {
        observer.disconnect();
        getChatToken();
        setInterval(() => {
          getChatToken();
        }, environment.chatTokenInterval);
      }
    };
    const bodyListener = () => {
      const bodyElement: HTMLElement = document.querySelector('body');
      const observer = new MutationObserver((mutatedEntries) => {
        mutatedEntries.forEach((mutation) => {
          const addedNodes = mutation.addedNodes;
          if (addedNodes.length > 0) {
            const addedElement: any = mutation.addedNodes[0];
            if (addedElement.id === 'wo_chatbox') {
              chatButtonBehavior(observer);
            }
          }
        });
      });
      observer.observe(bodyElement, { childList: true });
    };
    const whosOnScript = document.createElement('script');
    whosOnScript.id = whosonScriptId;
    whosOnScript.src = environment.chatUrl;
    whosOnScript.onload = showButton;
    document.body.appendChild(whosOnScript);
    bodyListener();
  }

  addMarketoTrackingCode() {
    const script = this.renderer.createElement('script');
    script.id = 'marketo';
    script.text = `!function(){var e=!1;function t(){!1===e&&(e=!0,Munchkin.init("${environment.marketoCodeId}"))}var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="${environment.marketoCodeSrcUrl}",a.onreadystatechange=function(){("complete"==this.readyState||"loaded"==this.readyState)&&t()},a.onload=t,document.getElementsByTagName("head")[0].appendChild(a)}();`;
    this.renderer.appendChild(document.body, script);
  }

  // addHotjar() {
  //   if (!isScullyRunning()) {
  //     const script = this.renderer.createElement('script');
  //     script.id = 'hotjar';
  //     script.text = `(function(h,o,t,j,a,r){
  //       h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  //       h._hjSettings={hjid:${environment.hotjar},hjsv:6};
  //       a=o.getElementsByTagName('head')[0];
  //       r=o.createElement('script');r.async=1;
  //       r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  //       a.appendChild(r);
  //       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  //     `;
  //     this.renderer.appendChild(document.body, script);
  //   }
  // }

  setPendoContext(visitorId?) {
    if (visitorId) {
      pendo.initialize({
        visitor: {
          id: visitorId,
        },
        account: {
          id: environment.pendoAccountId,
        },
      });
    } else {
      pendo.initialize({
        account: {
          id: environment.pendoAccountId,
        },
      });
    }
  }

  ngAfterViewChecked() {
    this.changeRef.detectChanges();
  }

  ngAfterViewInit(): void {
    if (!window.location.href.includes('/login')) {
      const previousPageUrl = this.localStorageService.getItem('previousPageUrl');
      const confirmRedirection = this.localStorageService.getItem('confirmRedirection');
      this.localStorageService.removeItem('previousPageUrl');
      this.localStorageService.removeItem('confirmRedirection');
      if (previousPageUrl && confirmRedirection) {
        this.router.navigateByUrl(previousPageUrl);
      }
    }
  }
}
