<div class="dashboard -container">
  <div class="dashboard -nav-vertical" #nav [ngClass]="{'-overlay-sec-nav': sidebarShow}">
    <small-screen-modal class="-overlay-container" [ngClass]="{'-overlay-slide-in': sidebarShow}" (click)="sidebarShow = !sidebarShow"></small-screen-modal>
    <span class="sidebar-slider -no-sec-nav-offset" [class.sidebar-slide-in]="sidebarShow">
      <nav-vertical-updated [allowLoaderControl]="false" slideNav="slideNav"></nav-vertical-updated>
      <div class="sidebar-opener" (click)="sidebarShow = !sidebarShow"></div>
    </span>
  </div>

  <div class="dashboard -body" #content>
    <router-outlet></router-outlet>
  </div>
</div>
