import { CognitoService } from '../../../services/cognito.service';
import { Component, Input } from '@angular/core';
import { LinkComponent } from '../link/link.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'link-icon',
  templateUrl: './link-icon.component.html',
  styleUrls: ['./link-icon.component.scss'],
})
export class LinkIconComponent extends LinkComponent {
  @Input() icon: string;
  @Input() linkclass: string;
  @Input() iconclass: string;

  constructor(public router: Router, public route: ActivatedRoute, public cognitoService: CognitoService) {
    super(router, route, cognitoService);

    if (!this.icon) {
      this.icon = 'thin-arrow-right';
    }
  }
}
