// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'test',
  apiHost: 'https://test-developer.fisglobal.com',
  selfcertApiHost: 'https://test-eapi.fisglobal.com',
  apiKey: '7htms2jKCO221ES39CnOt9B4Bg5nkyTW3bH01Uqa',
  sandboxApiHost: 'https://staging-developer.fisglobal.com',
  sandboxApiKey: 's7O6HjEUVk6nO8QNzUf53aOloXTEphvm9loVWVqY',
  UserPoolId: 'us-east-2_msWalRZ1x',
  // NO HTTPS, ONLY BASE URL
  AppWebDomain: 'test-auth-domain.auth.us-east-2.amazoncognito.com',
  ClientId: '1e7lr4db4vkv2cp3ifjo173slp',
  RedirectUriSignIn: 'https://test-developer.fisglobal.com/index.html',
  RedirectUriSignOut: 'https://test-developer.fisglobal.com/account/logout/',
  TokenScopesArray: ['email', 'phone', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
  instanaKey: '',
  hotjar: '',
  googleAnalytics: 'G-4K2BEBXFRE',
  content: '../../../catalyst-content/content',
  pendoApiKey: '',
  pendoAccountId: '',
  chatEnabled: true,
  chatUrl: '//worldpayusgw.whoson.com/include.js?domain=developerengine.fisglobal.com',
  chatTokenInterval: 190000,
  sfLeadSubmissionURL: 'https://fispayments--nauat.sandbox.my.salesforce-sites.com/servlet/servlet.WebToLead?encoding=UTF-8',
  sfOrgId: '00DDQ0000000Rkq',
  sfCampaignID: '7013h000000ULBBAA4',
  sfRecordType: '0123h000000MnxaAAC',
  marketoCodeId: '975-BCU-707',
  marketoCodeSrcUrl: '//munchkin.marketo.net/munchkin.js',
  googleTagManager: 'GTM-54625TJ8'
};
